var stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY);
var elements = stripe.elements();

$(document).ready(function(){
  // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '18px',
      }
    };

  // Create an instance of the card Element
    var card = elements.create('card', {style: style});
  // Add an instance of the card Element into the `card-element` <div>
    card.mount('#card-element');
    // card.mount('#example1-card');
  card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    // Create a token or display an error when the form is submitted.
    var form = document.getElementById('payment-form');
    form.addEventListener('submit', function(event) {
      var name = document.getElementById('name').value;
      console.log(name);
      if (!name) {
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = "You must enter a name.";
        errorElement.classList.add('visible');
        return;
      } else {
        event.preventDefault();
        var options = {
          name: name,
        };
        stripe.createToken(card, options).then(function(result) {
          if (result.error) {
            console.log(result.error);
            // Inform the user if there was an error
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            stripeTokenHandler(result.token);
          }
        });
      }
  }); 
});

 function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);
 ["brand", "exp_month", "exp_year", "last4"].forEach(function(field) {
    addFieldToForm(form, token, field);
  });
 // Submit the form
  form.submit();
}
 function addFieldToForm(form, token, field) {
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', "user[card_" + field + "]");
  hiddenInput.setAttribute('value', token.card[field]);
  form.appendChild(hiddenInput);
} 