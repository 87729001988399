require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
import './elements'
import Hls from 'hls.js/dist/hls.min.js'
import 'materialize-css/dist/js/materialize'
import '../stylesheets/application'

$(document).ready(function(){
  $('.sidenav').sidenav();
  $('select').formSelect();

  if (Hls.isSupported()) {
    var video = document.getElementById('video');
    if (video == null) {
      return false;
    }
    var src = 'https://d30ccsii05i7kv.cloudfront.net/output/hls/'+video.getAttribute('s3')+'.m3u8';
    var hls = new Hls({
        debug: true
    });
    hls.loadSource(src);
    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, function() {
      video.muted = true;
      video.play();
  });
  }
  // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
  // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
  // This is using the built-in support of the plain video element, without using hls.js.
  else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = src;
    video.addEventListener('canplay',function() {
      video.play();
    });
  }
});